import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "webhooks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#webhooks",
        "aria-label": "webhooks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webhooks`}</h1>
    <p>{`To obtain automatic transaction updates, or for notifications were the response is delayed, we support the following events for each transaction:`}</p>
    <p>{`For `}<strong parentName="p">{`Virtual Terminal`}</strong>{` transactions:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Webhook`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceCreated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when a transaction is successfully created in Medipass.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceCompleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when a transaction is successfully completed, and nothing left is owing to the provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceCancelled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction is cancelled by the provider, or rejected by the patient/cardholder.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For `}<strong parentName="p">{`Funder`}</strong>{` transactions:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Webhook`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceCompleted`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when a transaction is successfully completed, and nothing left is owing to the provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceCancelled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction is cancelled by the provider, or rejected by the patient/cardholder.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invoiceBalancePaid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notice on successful patient payment. When the balance goes to zero, this webhook will fire, regardless of how it was paid for.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundApprovedInvoice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For health fund delayed decision transactions where the claim was approved.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundRejectedInvoice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`for health fund delayed decision transactions where the claim was rejected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`healthFundPaidInvoice`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`for health fund delayed decision transactions where the claim was paid.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "event-handling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#event-handling",
        "aria-label": "event handling permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Event handling`}</h3>
    <h4 {...{
      "id": "retries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#retries",
        "aria-label": "retries permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Retries`}</h4>
    <p>{`We will attempt to retry failed webhooks if a timeout or '500' response is received from your endpoint. Retries are performed every 15 minutes for up to 24 hours or until a successful response is received.`}</p>
    <h4 {...{
      "id": "sequencing--ordering",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sequencing--ordering",
        "aria-label": "sequencing  ordering permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sequencing & ordering`}</h4>
    <p>{`We do not guarantee delivery of webhook events in the order in which they are generated. Although it is rare, payment and invoice status events could be received out of sequence. For example, you might have an invoiceCreated event arrive after invoiceCompleted event if payment is immediately processed.`}</p>
    <p>{`To handle this, we suggest to use the modified timestamp to determine if an update to status is appropriate for a given transaction. Alternatively, you can use our GET Invoice API to determine to current status.`}</p>
    <h3 {...{
      "id": "webhook-signature-optional",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#webhook-signature-optional",
        "aria-label": "webhook signature optional permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webhook signature (optional)`}</h3>
    <p>{`To enhance the webhook security further, we support signing the payload with SHA-256 hmac signature for each of the `}<strong parentName="p">{`POST`}</strong>{` webhook we sent. This will allow your server to ensure it's only receiving requests coming from Medipass.`}</p>
    <h4 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h4>
    <p>{`To set up the webhook signing, please contact the customer support to apply for a secret token that will be used for signature signing. Once the secret key is generated, it will be delivered either via keybase (preferred) or secure email.`}</p>
    <h4 {...{
      "id": "validating-request-from-medipass",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#validating-request-from-medipass",
        "aria-label": "validating request from medipass permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Validating request from Medipass`}</h4>
    <p>{`Once your secret token is set by Medipass, every `}<strong parentName="p">{`POST`}</strong>{` requests coming from Medipass will include two additional headers:`}</p>
    <ul>
      <li parentName="ul">{`X-Sender-Signature | A SHA-256 HMAC hash that's generated based on X-Sender-Timestamp value and JSON stringified payload.`}</li>
      <li parentName="ul">{`X-Sender-Timestamp | Date in ISO date string format. It represents the date the request was sent. Also, It will be used for HMAC hash calculation.`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`...
X-Sender-Signature=215d022a9e9c95fab7ca7c618d0d7b8d9e6dca1055d544b3d2421312a16a5651
X-Sender-Timestamp="2021-01-13T04:23:50.659Z"
`}</code></pre>
    <p>{`To verify the hmac signature, you will need to compute your own SHA-256 HMAC signature and compare it with the signature provided in the header. So the code will be something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const hmacSignature = Crypto.createHmac("sha256", SECRET_TOKEN)
                            .update(\`\${headers["X-Sender-Timestamp"]}\${JSON.stringify(payload)}\`)
                            .digest("hex");
return Crypto.timingSafeEqual(new Buffer.from(hmacSignature, "utf-8"), new Buffer.from(headers["X-Sender-Signature"], "utf-8"));
`}</code></pre>
    <p>{`Implementation between different languages might be different. However, things to note above are:`}</p>
    <ul>
      <li parentName="ul">{`The HMAC function has to use SHA256 method`}</li>
      <li parentName="ul">{`The base for computing the hash is consisted of the timestamp in the header and stringified payload in the request`}</li>
      <li parentName="ul">{`Try to use timingSafeEqual equivalent function to compare the HMAC result to avoid timing attack on large string comparison`}</li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <p>{`To set a webhook, use the `}<inlineCode parentName="p">{`webhooks`}</inlineCode>{` attribute and provide:`}</p>
    <ul>
      <li parentName="ul">{`URL (usually transaction specific)`}</li>
      <li parentName="ul">{`webhook event: one of the above listed events`}</li>
      <li parentName="ul">{`method: POST/GET/PUT/DELETE`}</li>
      <li parentName="ul">{`Any required headers (your transaction specific ID or authentication header could be set here)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  ...
  webhooks: [
    {
      url: 'https://your-site.com/transactions/your-transactionId/success',
      event: 'healthFundApprovedInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    },
    {
      url: 'https://your-site.com/transactions/your-transactionId/failure',
      event: 'healthFundRejectedInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    },
    {
      url: 'https://your-site.com/transactions/your-transactionId/paid',
      event: 'healthFundPaidInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    }
  ]
  ...
})
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  ...
  webhooks: [
    {
      url: 'https://your-site.com/transactions/your-transactionId/event-triggers',
      event: 'healthFundApprovedInvoice,healthFundRejectedInvoice,healthFundPaidInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    }
  ]
  ...
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      